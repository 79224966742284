export const uc1 = {
  "toolDetails": {
    "toolName": "Risk Assessment",
    "toolVersion": "5.1",
    "jsonOutputVersion": "0.6"
  },
  "generalDetails": {
    "riskAssessmentTitle": "Sharing licenseV4 dataset to ICCS",
    "userName": "Christos Baloukas",
    "organizationName": "HPOL",
    "organizationType": "LEA",
    "purposeOfSharing": "AI Model Training",
    "datasetModel": "Dataset",
    "datasetNature": "Real",
    "nameOfRequestingEntity": "ICCS",
    "typeOfRequestingEntity": "Research Institute / University",
    "customTypeOfRequestingEntity": "",
    "comments": "In this demo assessment, we examine a hypothetical scenario for a FCT data exchange between ICCS (Research Institute) and Hellenic Police (LEA). ICCS requested images from traffic cameras to train an AI tool that will follow the movement of vehicles through the city.",
    "exportDate": "Wed, 4 Sept 2024",
    "dataTypes": [
      "License plates"
    ],
    "specialDataTypes": [
      "No Special Data"
    ],
    "dataFormats": [
      "Images"
    ]
  },
  "datasetContainsPersonalData": "Yes",
  "datasetContainsSensitiveData": "No",
  "impactLevels": {
    "confidentiality": {
      "checked": true,
      "levels": {
        "personal": {
          "checked": true,
          "level": "Medium",
          "comments": "If images with personal license plates and the corresponding cars leak, this may have a Medium impact because, for example, criminals may copy the license plate of a person along with their car type and implicate them in criminal activities, therefore creating inconveniences for the people."
        },
        "institutional": {
          "checked": true,
          "level": "High",
          "comments": "If the dataset is leaked, it may induce a loss of trust to the LEA which will affect its operations as people may object to LEA's using traffic video cameras. This may need serious adjustments to operations and may require large amounts of time and funds to overcome. This constitutes a High impact. Also, if the license agreement between the LEA and the research institute does not cover GDPR, this may create legal issues and loss of funds, which is a Medium impact according to Institutional Impact Table. So the final impact level for the institution is High"
        },
        "societal": {
          "checked": true,
          "level": "Medium",
          "comments": "If there is a dataset leak, people may display distrust towards LEAs, ministries and government in general, which is a Medium impact level according to the Societal Impact Table"
        }
      }
    },
    "bias": {
      "checked": true,
      "levels": {
        "personal": {
          "checked": true,
          "level": "None",
          "comments": "Since the traffic camera images show vehicles and their license plates low quality data could mean that images show certain types of cars or certain angles only. The AI model that will be developed using this data maybe won't be very capable. This does not have any personal impact. "
        },
        "institutional": {
          "checked": true,
          "level": "Low",
          "comments": "Since the traffic camera images show vehicles and their license plates low quality data could mean that images show certain types of cars or certain angles only. The AI model that will be developed using this data maybe won't be very capable. In that case, maybe ICCS will turn elsewhere for data and the whole process will be a wast of time. This can result in minor Institutional impact for HPOL because of the lost time and resources."
        },
        "societal": {
          "checked": true,
          "level": "None",
          "comments": "Since, the traffic camera images show vehicles and their license plates low quality data could mean that images show certain types of cars or certain angles only. The AI model that will be developed using this data maybe won't be very capable. In that case, maybe ICCS will turn elsewhere for data and the whole process will be a wast of time. This will have no societal impact."
        }
      }
    },
    "legal": {
      "checked": true,
      "levels": {
        "personal": {
          "checked": true,
          "level": "None",
          "comments": "Not complying with the legal frameworks will have impact on just our organization not on people."
        },
        "institutional": {
          "checked": true,
          "level": "Medium",
          "comments": "Since we are sharing data license plates that are considered personal data, we need to comply with GDPR. Failing to do so could result in minor legal fines, which constitutes a medium institutional impact accroding to the impact tables."
        },
        "societal": {
          "checked": true,
          "level": "None",
          "comments": "Not complying with the legal frameworks will have an impact on our organization but our operations will continue as is and therefore our job towards protecting society will continue without disruptions. No societal impact is predicted."
        }
      }
    },
    "misuse": {
      "checked": true,
      "levels": {
        "personal": {
          "checked": true,
          "level": "High",
          "comments": "The data contain private vehicles doing their daily routines. If misused the data could be used to track certain individuals through their daily routines, or identify their homes, workplaces etc. This is a potential violation of privacy, which is a High personal impact."
        },
        "institutional": {
          "checked": true,
          "level": "Medium",
          "comments": "Misusing traffic camera data could lead to protests about HPOL storing traffic data or even traffic cameras in general. This may result in minor loss of trust and a change in our operations to accommodate for restrictions in traffic camera use, which is a Medium institutional impact. "
        },
        "societal": {
          "checked": true,
          "level": "High",
          "comments": "The AI model that ICCS will create may be used for private surveillance purposes by HPOL if not used appropirately, which could constitute a significant violation of privacy that will have significant impact on our society in general. Large demonstrations and major distrust towards government agencies can be foreseen in that case, which is a High societal impact."
        }
      }
    }
  },
  "threatLevels": {
    "privacy": {
      "checked": true,
      "answers": {
        "anonymization": {
          "answer": "No",
          "comments": "The requester needs the original license plates to train the AI model.",
          "checked": true
        },
        "verified": {
          "answer": "",
          "comments": "",
          "checked": false
        },
        "manualVerification": {
          "answer": "",
          "comments": "",
          "checked": false
        },
        "complete": {
          "answer": "",
          "comments": "",
          "checked": false
        }
      },
      "level": "N/A"
    },
    "bias": {
      "checked": true,
      "answers": {
        "biasChecked": {
          "answer": "Yes",
          "comments": "The images do not contain personal information (other than license plates), so they do not exhibit any bias towards people.",
          "checked": true
        },
        "qualityChecked": {
          "answer": "Yes",
          "comments": "All images are clear with proper brightness and contrast levels.",
          "checked": true
        },
        "purposeChecked": {
          "answer": "Yes",
          "comments": "The images have been checked manually that they show different angles of cars and a variety of cars and congestion situations. Bias related to people is not applicable here.",
          "checked": true
        },
        "toolUsed": {
          "answer": "Yes",
          "comments": "The LAGO DQ tool has been used to verify the quality of the individual images.",
          "checked": true
        },
        "verified": {
          "answer": "No",
          "comments": "",
          "checked": true
        }
      },
      "level": "Low"
    },
    "transfer": {
      "checked": true,
      "answers": {
        "electronic": {
          "answer": "Electronic",
          "comments": "The data will be sent through a service provided by ICCS.",
          "checked": true
        },
        "security": {
          "answer": "Yes",
          "comments": "The transfer method uses end-to-end encryption ",
          "checked": true
        },
        "thirdPartyServer": {
          "answer": "No",
          "comments": "The data are transferred directly to ICCS's servers.",
          "checked": true
        },
        "portable": {
          "answer": "No",
          "comments": "The data are transferred electronically.",
          "checked": true
        },
        "bestPractices": {
          "answer": "Yes",
          "comments": "The data are transferred electronically.",
          "checked": true
        }
      },
      "level": "Low"
    },
    "cybersecurity": {
      "checked": true,
      "answers": {
        "standard": {
          "answer": "No",
          "comments": "",
          "checked": true
        },
        "securityMeasures": {
          "answer": "No",
          "comments": "",
          "checked": true
        },
        "accessRestrictions": {
          "answer": "Yes",
          "comments": "",
          "checked": true
        },
        "lifecycle": {
          "answer": "Yes",
          "comments": "",
          "checked": true
        },
        "experience": {
          "answer": "Yes",
          "comments": "",
          "checked": true
        },
        "thirdPartyUsers": {
          "answer": "Yes",
          "comments": "",
          "checked": true
        },
        "audit": {
          "answer": "No",
          "comments": "",
          "checked": true
        },
        "cyberattack": {
          "answer": "No",
          "comments": "",
          "checked": true
        }
      },
      "level": "Medium"
    },
    "legalUnauthorized": {
      "checked": true,
      "answers": {
        "licenseExist": {
          "answer": "Yes",
          "comments": "",
          "checked": true
        },
        "dataDistribution": {
          "answer": "No",
          "comments": "",
          "checked": true
        },
        "workDistribution": {
          "answer": "No",
          "comments": "",
          "checked": true
        },
        "publish": {
          "answer": "Yes",
          "comments": "",
          "checked": true
        },
        "permanent": {
          "answer": "No",
          "comments": "",
          "checked": true
        },
        "evidence": {
          "answer": "Yes",
          "comments": "",
          "checked": true
        },
        "domain": {
          "answer": "No",
          "comments": "",
          "checked": true
        }
      },
      "level": "Low"
    },
    "legalCompliance": {
      "checked": true,
      "answers": {
        "clausesPersonal": {
          "answer": "Yes",
          "comments": "",
          "checked": true
        },
        "storageOutside": {
          "answer": "No",
          "comments": "",
          "checked": true
        },
        "fctregulations": {
          "answer": "Yes",
          "comments": "",
          "checked": true
        }
      },
      "level": "Low"
    },
    "misuse": {
      "checked": true,
      "answers": {
        "surveillance": {
          "answer": "Yes",
          "comments": "",
          "checked": true
        },
        "manipulation": {
          "answer": "No",
          "comments": "",
          "checked": true
        },
        "endanger": {
          "answer": "Yes",
          "comments": "",
          "checked": true
        },
        "generalPurpose": {
          "answer": "No",
          "comments": "",
          "checked": true
        }
      },
      "level": "High"
    },
    "total": {
      "unauthorized": "Medium",
      "bias": "Low",
      "legal": "Low",
      "misuse": "High"
    }
  },
  "riskLevel": {
    "unauthorized": {
      "personal": "Medium",
      "personalScore": "",
      "institutional": "High",
      "institutionalScore": "",
      "societal": "Medium",
      "societalScore": "",
      "total": "",
      "totalScore": ""
    },
    "bias": {
      "personal": "None",
      "personalScore": "",
      "institutional": "Low",
      "institutionalScore": "",
      "societal": "None",
      "societalScore": "",
      "total": "",
      "totalScore": ""
    },
    "legal": {
      "personal": "None",
      "personalScore": "",
      "institutional": "Medium",
      "institutionalScore": "",
      "societal": "None",
      "societalScore": "",
      "total": "",
      "totalScore": ""
    },
    "misuse": {
      "personal": "High",
      "personalScore": "",
      "institutional": "High",
      "institutionalScore": "",
      "societal": "High",
      "societalScore": "",
      "total": "",
      "totalScore": ""
    },
    "total": {
      "personal": "",
      "personalScore": "",
      "institutional": "",
      "institutionalScore": "",
      "societal": "",
      "societalScore": "",
      "total": "",
      "totalScore": ""
    }
  },
  "mitigationActions": [
    {
      "riskfactor": "Requester not following a cybersecurity standard/framework on their premises.",
      "action": "Oblige the requester through the appropriate license agreement to implement a cybersecurity standard/framework and get certified (e.g. ISO27001, NIST Cybersecurity Framework, etc)."
    },
    {
      "riskfactor": "Requester not implementing SOTA security measures for their technical infrastructure.",
      "action": "Oblige the requester through the appropriate license agreement to implement SOTA security measures for their technical infrastructure like the following: 1) Enforce the use of strong passwords and multi-factor authentication. 2) Update and enforce security policies like computer settings that require the users to enter their password if they are away for a few minutes. 3) Security updates are regularly installed and data are regularly backed up. 4) Data backups are being checked regularly for integrity."
    },
    {
      "riskfactor": "Requester not being audited by an external party for cybersecurity.",
      "action": "Oblige the requester through the appropriate license agreement to perform regular audits with external cybersecurity experts to check issues with the following areas: 1) Network access control, encryption use, data security at rest, and transmissions 2) Security policies, procedures, and controls. 3) Network & security controls, anti-virus configurations, security monitoring capabilities, etc. 4) Hardening processes, patching processes, privileged account management, role-based access, etc. 5) Disk encryption, role-based access controls, biometric data, multi-factor authentication, etc."
    },
    {
      "riskfactor": "Data may be used to create systems (including AI) that may endanger health, safety or fundamental rights of persons.",
      "action": "Such systems are subject to monitoring and additional requirements by the EU. Ensure that the requester is compliant with these requirements before sharing the data."
    }
  ],
  "datasetSectionsMenu": [
    {
      "title": "Definition and Context of the Sharing Operation",
      "id": "1",
      "active": false,
      "visited": true,
      "enabled": true
    },
    {
      "id": "2",
      "title": "Impact Evaluation",
      "visited": true,
      "active": false,
      "enabled": true,
      "subNav": [
        {
          "id": "2a",
          "title": "Unauthorized Access (loss of confidentiality)",
          "visited": true,
          "active": false,
          "enabled": true
        },
        {
          "id": "2b",
          "title": "Low Quality Dataset",
          "visited": true,
          "active": false,
          "enabled": true
        },
        {
          "id": "2c",
          "title": "Legal Non-Compliance",
          "visited": true,
          "active": false,
          "enabled": true
        },
        {
          "id": "2d",
          "title": "Data Misuse",
          "visited": true,
          "active": false,
          "enabled": true
        },
        {
          "id": "2e",
          "title": "Overall Impact Evaluation",
          "visited": true,
          "active": false,
          "enabled": true
        }
      ]
    },
    {
      "id": "3",
      "title": "Threat Analysis",
      "visited": true,
      "active": false,
      "enabled": true,
      "subNav": [
        {
          "id": "3a",
          "title": "Unauthorized Access",
          "visited": true,
          "active": false,
          "enabled": true,
          "subNav": [
            {
              "id": "3a.1",
              "title": "Privacy Preservation",
              "visited": true,
              "active": false,
              "enabled": true
            },
            {
              "id": "3a.2",
              "title": "Data Transfer",
              "visited": true,
              "active": false,
              "enabled": true
            },
            {
              "id": "3a.3",
              "title": "Cybersecurity",
              "visited": true,
              "active": false,
              "enabled": true
            },
            {
              "id": "3a.4",
              "title": "License Agreement",
              "visited": true,
              "active": false,
              "enabled": true
            }
          ]
        },
        {
          "id": "3b",
          "title": "Low Quality Dataset",
          "visited": true,
          "active": false,
          "enabled": true
        },
        {
          "id": "3c",
          "title": "Legal Non-Compliance",
          "visited": true,
          "active": false,
          "enabled": true
        },
        {
          "id": "3d",
          "title": "Data Misuse",
          "visited": true,
          "active": false,
          "enabled": true
        },
        {
          "id": "3e",
          "title": "Overall Threat Levels",
          "visited": true,
          "active": false,
          "enabled": true
        }
      ]
    },
    {
      "id": "4",
      "title": "Risk Evaluation",
      "visited": true,
      "active": false,
      "enabled": true
    },
    {
      "id": "5",
      "title": "Mitigation Actions",
      "visited": true,
      "active": false,
      "enabled": true
    },
    {
      "id": "6",
      "title": "Assessment Export",
      "visited": true,
      "active": true,
      "enabled": true
    }
  ]
}