import React from 'react';
import * as S from './styles';
import LogoLight from './assets/images/logo.svg';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

export default class HomeComponent extends React.Component {

  render () {

    return(
      <Container>
        <Row>
          <Col md>
            <S.Card>
              <S.CardBody>
                <S.MediumWidthDiv>
                <S.FlexCenterDirColumnDiv>
                  <S.MainComponentHeaderLogo src={LogoLight} style={{width:400}}></S.MainComponentHeaderLogo>
                  <h2>Risk Assessment Tool for Sharing FCT Data</h2>
                  <p>Please start an assessment by clicking on the buttons below:</p>
                  <S.MainComponentLinksWrapper>
                  
                  <Link to='/riskv5' style={{marginBottom:80}}>
                    <span style={{ fontSize:20, paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#3D5A80'}} className='button' >I am a data provider</span>
                  </Link>
                  <Link to='/' style={{marginBottom:80}}>
                    <span style={{ fontSize:20, paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#3D5A80'}} className='button' >I am a data requester (not available yet)</span>
                  </Link>
                  <Link to='/newdemo' style={{marginBottom:80}}>
                    <span style={{ fontSize:20, paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#3D5A80'}} className='button' >Use Case Example 1</span>
                  </Link>
                  
                  {false && <>
                  <Link to='/demo' style={{marginBottom:80}}>
                    <span style={{ fontSize:20, paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#3D5A80'}} className='button' >Demo</span>
                  </Link>
                  <Link to='/parisdemo' style={{marginBottom:80}}>
                    <span style={{ fontSize:20, paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#3D5A80'}} className='button' >Paris Demo</span>
                  </Link>
                  </>}
                  
                  
                    {
                    /*
                    <S.MainComponentLink href='https://localhost:9090/docs/commonitor/' target='_blank'>Documentation</S.MainComponentLink>
                    <S.MainComponentLink href='mailto:christosbaloukas@microlab.ntua.gr'>Contact</S.MainComponentLink>
                    */}
                  </S.MainComponentLinksWrapper>
                  
                </S.FlexCenterDirColumnDiv>
                </S.MediumWidthDiv>
              </S.CardBody>
            </S.Card>
          </Col>
        </Row>
      </Container>
    )
  }

}